import React, { FC, useEffect } from 'react';
import Head from 'next/head';
import { useAnalytics } from 'common/hooks/useAnalytics';
import { AnimatePresence } from 'framer-motion';
import { ApolloProvider } from '@apollo/client';
import {
  getApolloClient,
  NextApolloPageContext,
  useApollo
} from 'common/hooks/useApollo';
import { GlobalStateProvider } from 'common/components/GlobalStateProvider';
import { parseCookies } from 'nookies';
import { AppProps } from 'next/app';
import { NextComponentType, NextPageContext } from 'next';
import { AppContextType } from 'next/dist/shared/lib/utils';
import { MeDocument } from 'user/queries/me';
import { AUTH_COOKIE_NAME } from 'authentication/schema/schema';
import { EmotionCache } from '@emotion/utils';
import { CacheProvider } from '@emotion/react';
import { createEmotionCache } from 'common/methods/createEmotionCache';
import dynamic, { LoaderComponent } from 'next/dynamic';
import { GlobalPlayer } from 'project/components/GlobalPlayer';
import { ThemeProvider } from 'common/components/ThemeProvider';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export interface CustomAppProps extends Omit<AppProps, 'Component'> {
  Component: NextComponentType<NextPageContext> & {
    layout: FC;
  };
  emotionCache?: EmotionCache;
  cookies: Record<string, unknown>;
}
const clientSideEmotionCache = createEmotionCache();

const AuthenticatedLayout = dynamic(
  () =>
    import('common/components/AuthenticatedLayout').then(
      (mod) => mod.AuthenticatedLayout
    ) as LoaderComponent<unknown>
);

const CustomApp = (props: CustomAppProps) => {
  const {
    Component,
    pageProps,
    emotionCache = clientSideEmotionCache,
    router
  } = props;
  const Layout = Component?.layout || AuthenticatedLayout;
  const analytics = useAnalytics();
  const { layoutProps = {} } = pageProps;
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    console.log(
      `%c
                     ▓                                    ▓▓                                                            
                   ▒▓▓▓▓▒                               ▓▓▓▓▓                                                           
                   ▒▓▓▓▓▓▓▒            ░▒             ▒▓▓▓▓▓▓                                                           
                   ▒▓▓▓▓▓▓▓▓          ▒▒▒▒          ▒▓▓▓▓▓▓▓▓                                                           
                   ▒▓▓▓▒▒▓▓▓▓▒        ▒▒▒▒        ░▓▓▓▓▓░▓▓▓▓                                                           
                   ▒▓▓▓▒  ▒▓▓▓▓▓      ░▒▒▒       ▓▓▓▓▓▒  ▓▓▓▓                ▒▒▒                                        
                   ▒▓▓▓▒    ▒▓▓▓▓▒             ▓▓▓▓▓▒    ▓▓▓▓       ░░       ▒▒▒                                        
                   ▒▓▓▓▒      ▓▓▓▓▓▒         ▓▓▓▓▓▓      ▓▓▓▓      ▒▒▒▒      ▒▒▒                                        
          ▒▒▒▒     ▒▓▓▓▒        ▓▓▓▓▓      ▒▓▓▓▓▓        ▓▓▓▓      ▒▒▒▒      ▒▒▒                                        
          ▒▒▒▒     ▒▓▓▓▒         ▒▓▓▓▓▓  ░▓▓▓▓▓░         ▓▓▓▓      ▒▒▒▒      ▒▒▒                                        
▒▒▒░      ▒▒▒▒     ▒▓▓▓▒           ▒▓▓▓▓▓▓▓▓▓            ▓▓▓▓      ▒▒▒▒      ░▒▒          Don't hack me, bro!           
▒▒▒▒      ▒▒▒▒     ▒▓▓▓▒             ▓▓▓▓▓▓▒             ▓▓▓▓      ▒▒▒▒                                                 
▒▒▒▒      ▒▒▒▒     ▒▓▓▓▒               ▓▓▒               ▓▓▓▓      ▒▒▒▒                                                 
▒▒▒▒      ▒▒▒▒     ▒▓▓▓▒     ▒▒▒▒                        ▓▓▓▓      ▒▒▒▒                                                 
▒▒▒▒      ▒▒▒▒     ▒▓▓▓▒     ▒▒▒▒               ▒▒▒▒     ▓▓▓▓      ▒▒▒▒                                                 
▒░░       ▒▒▒▒     ▒▓▓▓▒     ▒▒▒▒               ▒▒▒▒     ▓▓▓▓       ░░                                                  
          ▒▒▒▒     ▒▓▓▓▒     ▒▒▒▒     ░▒▒▒      ▒▒▒▒     ▓▓▓▓                                                           
          ▒▒▒░     ▒▓▓▓▒     ▒▒▒▒     ▒▒▒▒      ▒▒▒▒     ▓▓▓▓                                                           
                   ▒▓▓▓▒     ░▒░      ▒▒▒▒      ░▒▒      ▒▓▓▓                                                           
                   ▒▓▓▓▒               ▒▒░                                                                              
                   ░▓▓▓                                                                                                 `,
      'background: #281A33; color: white'
    );
  }, []);

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(publicRuntimeConfig.facebookPixel);
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
          if (typeof window === 'undefined') {
            return;
          }
          analytics.capture('$pageview');
        });
      });

    /* @ts-ignore */
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      //@ts-ignore
      dataLayer.push(arguments);
    }
    //@ts-ignore
    gtag('js', new Date());

    //@ts-ignore
    gtag('config', publicRuntimeConfig.googleTag);
    //@ts-ignore
    !(function (w, d) {
      //@ts-ignore
      if (!w.rdt) {
        //@ts-ignore
        var p = (w.rdt = function () {
          //@ts-ignore
          p.sendEvent
            ? //@ts-ignore
              p.sendEvent.apply(p, arguments)
            : //@ts-ignore
              p.callQueue.push(arguments);
        });
        //@ts-ignore
        p.callQueue = [];
        var t = d.createElement('script');
        (t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !0);
        var s = d.getElementsByTagName('script')[0];
        //@ts-ignore
        s.parentNode.insertBefore(t, s);
      }
    })(window, document);
    //@ts-ignore
    rdt('init', publicRuntimeConfig.redditPixel);
    //@ts-ignore
    rdt('track', 'PageVisit');
  }, [router.events]);

  const handleRouteChange = () => {
    window.scrollTo(0, 0);
  };

  return (
    <ThemeProvider>
      <CacheProvider value={emotionCache}>
        <ApolloProvider client={apolloClient}>
          <GlobalStateProvider cookies={props.cookies}>
            <Head>
              <link rel='preconnect' href='https://fonts.googleapis.com' />
              <link
                rel='preconnect'
                href='https://fonts.gstatic.com'
                crossOrigin={'true'}
              />
              <link rel='shortcut icon' href='/favicon.ico' />
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
              />
              <meta name='HandheldFriendly' content='true' />
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.googleTag}`}
              ></script>
            </Head>
            <AnimatePresence exitBeforeEnter onExitComplete={handleRouteChange}>
              <Layout key={router?.asPath} {...layoutProps}>
                <Component key={router?.asPath} {...pageProps} />
              </Layout>
            </AnimatePresence>
            <GlobalPlayer />
          </GlobalStateProvider>
        </ApolloProvider>
      </CacheProvider>
    </ThemeProvider>
  );
};

CustomApp.getInitialProps = async ({ Component, ctx }: AppContextType) => {
  const cookies = parseCookies(ctx);
  const apolloClient = getApolloClient(undefined, ctx as NextApolloPageContext);

  if (cookies[AUTH_COOKIE_NAME]) {
    await apolloClient.query({ query: MeDocument, errorPolicy: 'ignore' });
  }

  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : {};

  return { pageProps, cookies };
};

export default CustomApp;
